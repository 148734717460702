<template>
  <div class="item-container" @click="info(item.id)">
    <div class="content">
      <img class="top-pic" :src="item.pic">
      <div class="title">{{item.name}}</div>
      <div class="info">
        <div class="text">
          学制课时：<font class="class-period">{{item.class_period}}</font> /
          每年：<font class="class-time">{{item.class_time}}</font>课
        </div>
        <div class="action">
          <div class="button">
            详情
            <SvgIcon name="next" class="icon" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    item: {
      type: Object,
      default() {
        return {};
      },
    },
  },
  methods: {
    info(id) {
      this.$router.push({ path: "/textbook_info", query: { id: id } });
    },
  },
};
</script>

<style lang="scss" scoped>
.item-container {
  width: 305px;
  height: 388px;
  background: #ffffff;
  box-shadow: 0px 2px 16px 0px rgba(0, 0, 0, 0.11);
  border-radius: 16px;
  overflow: hidden;

  .content {
    position: relative;

    .top-pic {
      width: 305px;
      height: 225px;
      border-radius: 16px 16px 0px 0px;
      cursor: pointer;
      &:hover {
        transition: 0.5s;
        transform: scale(1.06);
      }
    }

    .title {
      font-size: 18px;

      line-height: 25px;
      font-family: PingFangSC-Medium, PingFang SC;
      text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);
      font-weight: bold;
      color: #333333;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-top: 22px;
      margin-left: 15px;

      cursor: pointer;
      &:hover {
        padding-left: 5px;
        transition: 0.5s;
        transform: scale(1.06);
      }
    }

    .info {
      display: flex;
      flex-direction: column;

      .text {
        margin-top: 8px;
        margin-left: 15px;

        height: 20px;
        font-size: 14px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #999999;
        line-height: 20px;
        text-shadow: 0px 2px 16px rgba(0, 0, 0, 0.11);

        .class-time,
        .class-period {
          color: #ff6f1b;
        }
      }

      .action {
        .button {
          cursor: pointer;

          margin-left: auto;
          margin-right: 20px;
          margin-top: 30px;

          display: flex;
          align-items: center;
          justify-content: center;

          width: 70px;
          height: 30px;
          border-radius: 26px;

          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ff6f1b;
          line-height: 22px;

          .icon {
            margin-top: 3px;
            margin-left: 2px;
            width: 8px;
            height: 12px;
          }

          border: 1px solid #ff6f1b;
          cursor: pointer;
          transition: 0.5s;
          &:hover {
            transform: scale(1.06);
          }
        }
      }
    }
  }

  &:hover {
    transition: 0.5s;
    transform: scale(1.06);
    // margin: 0 34px;
  }
}
</style>