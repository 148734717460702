<template>
  <div class="container">
    <!-- <div v-if="false"> -->
    <Carousel :args="args.slide" />
    <!-- </div> -->
    <Textbooks :args="args.textbook"/>
  </div>
</template>

<script>
import Carousel from "@/components/common/carousel.vue";
import Textbooks from "@/components/textbook/textbooks.vue";
export default {
  components: {
    Carousel,
    Textbooks,
  },
  created() {
    this.$api("/official/page/textbook").then(({ data }) => {
      this.args = data;
    });
  },
  data() {
    return {
      args: {},
    };
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.container {
  width: 1440px;
}
</style>

