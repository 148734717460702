<template>
  <div class="textbooks_container">
    <div class="top">
      <div class="main-title">课程<font> / COURSE</font>
        <div class="sub-title">科学专业 生动有趣</div>
      </div>
    </div>
    <div class="content">
      <div class="tabs">
        <div @click="clickTabHandler(tab.index)" :class="{tab:true, actived:tabCurr == tab.index}" v-for="tab in tabs"
          :key="tab.index">
          <img :src="tabCurr == tab.index ? tab.icon_selected :tab.icon">
          {{tab.name}}
        </div>
      </div>
      <div :class="{textbooks:true, actived:show}">
        <Textbook class="textbook1" title="幼儿阶段/BABY" :items="list[0]" />
        <Textbook class="textbook2" title="少儿阶段/CHILD" :items="list[1]"
          background="url(/static/img/textbook/child_bg.png)" titleColor="white" />
        <Textbook class="textbook3" title="初高中阶段/YOUNG" :items="list[2]" />
        <Textbook class="textbook4" :items="list[3]" />
      </div>
    </div>
  </div>
</template>

<script>
import Textbook from "./textbook.vue";
export default {
  components: {
    Textbook,
  },
  data() {
    return {
      show: true,
      category: 0,
      tabCurr: 0,
      tabs: [
        {
          index: 0,
          name: "演播课程类",
          icon: "/static/img/index/course/icon-studio-unselect.png",
          icon_selected: "/static/img/index/course/icon-studio-selected.png",
        },
        // {
        //   index: 1,
        //   name: "思维口语类",
        //   icon: "/static/img/index/course/icon-spoken-unselect.png",
        //   icon_selected: "/static/img/index/course/icon-spoken-selected.png",
        // },
        {
          index: 2,
          name: "精品课程类",
          icon: "/static/img/index/course/icon-feature-unselect.png",
          icon_selected: "/static/img/index/course/icon-feature-selected.png",
        },
      ],
      //展示数据
      list: {
        0: [],
        1: [],
        2: [],
        3: [],
      },
      allList: [],
    };
  },
  props: {
    args: {
      type: Array,
      default: () => [],
    },
  },
  watch: {
    args(args) {
      this.allList = args;
      this.loadList();
    },
  },
  methods: {
    loadList() {
      this.list[0] = [];
      this.list[1] = [];
      this.list[2] = [];
      this.list[3] = [];
      this.allList.forEach((item) => {
        if (item.category != this.tabCurr) {
          return;
        }
        // if (this.list[item.phase].length >= 3) {
        //   return;
        // }
        this.list[item.phase].push(item);
      });
    },
    clickTabHandler(idx) {
      if (this.tabCurr == idx) {
        return;
      }
      this.tabCurr = idx;
      this.show = false;
      this.loadList();
      setTimeout(() => {
        this.show = true;
      }, 500);
    },
  },
};
</script>

<style lang="scss" scoped>
.textbooks_container {
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      padding-top: 80px;
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
    .sub-title {
      text-align: center;
      margin-top: 10px;
      font-size: $font-size-16;
      color: $font-color-gray;
    }
  }
  .content {
    .tabs {
      margin-top: 50px;
      display: flex;

      justify-content: center;

      .tab {
        width: 174px;
        height: 48px;
        border-radius: 24px;

        display: flex;
        align-items: center;
        justify-content: center;

        font-size: 16px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #ff6f1b;
        line-height: 22px;

        margin: 0 15px;

        img {
          margin-right: 12px;
          width: 25px;
          // height: 25px;
        }

        &:hover {
          transform: scale(1.1);
          border: 1px solid #ff6f1b;
        }

        cursor: pointer;
        transition: 0.5s;
        background: white;
        border: 1px solid white;

        &.actived {
          color: white;
          background: linear-gradient(158deg, #ffa83a 0%, #ff6f1b 100%);
        }
      }
    }

    .textbooks {
      visibility: hidden;
      opacity: 0;

      padding-bottom: 80px;

      &.actived {
        visibility: visible;
        opacity: 1;
        transition: 1s;
      }

      .textbook1 {
        padding-top: 80px;
        padding-bottom: 80px;
      }
      .textbook2 {
        padding-top: 50px;
        padding-bottom: 117px;
      }
      .textbook3 {
        padding-top: 80px;
        padding-bottom: 80px;
      }
    }
  }
}
</style>