<template>
  <div class="textbook-container" :style="{background:background}" v-show="items.length">
    <div class="top" v-if="title">
      <div class="main-title" :style="{color:titleColor ? titleColor + '!important' : '#333'}">
        {{titleFirst}} <font :style="{color:titleColor ? titleColor + '!important' : '#ff6f1b'}">{{titleCategory}}
        </font>
      </div>
    </div>
    <div class="body">
      <div :class="{carousel:true, actived:itemShow}">
        <el-carousel ref="carousel" @change="changeCarouselHandle" indicator-position="none" arrow="never" height="100%"
          :interval="5000">
          <el-carousel-item class="items" v-for="(idx2, key2) in itemsPage" :key="key2">
            <Item :class="{item:true, not:!items[idx3]} " v-for="(idx3, key3) in range((idx2 * 3 - 3), (idx2 * 3 - 1))"
              :key="key3" :item="items[idx3]" />
          </el-carousel-item>
        </el-carousel>
      </div>
      <div :class="{action:true, action_white:background != 'none'}">
        <div class="pre" @click="changeCarouselIndex(carouselIndicatorIndex - 1)"></div>
        <div class="next" @click="changeCarouselIndex(carouselIndicatorIndex + 1)"></div>
      </div>
      <div :class="{indicator:true, actived:itemShow}">
        <div @click="changeCarouselIndex(key2)"
          :class="{point:true, actived:carouselIndicatorIndex == key2, action_white:background != 'none'}"
          v-for="(idx2, key2) in itemsPage" :key="key2">
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Item from "./item.vue";
export default {
  components: { Item },
  data() {
    return {
      itemIndex: 0,
      itemShow: true,
      carouselIndicatorIndex: 0,
    };
  },
  methods: {
    range(start, end, step = 1) {
      let items = [];
      for (let i = start; i <= end; i = i + step) {
        items.push(i);
      }
      return items;
    },
    changeCarouselHandle(idx) {
      this.carouselIndicatorIndex = idx;
    },
    changeCarouselIndex(index) {
      if (index > this.itemsPage - 1) {
        index = 0;
      }
      if (index < 0) {
        index = this.itemsPage - 1;
      }
      this.carouselIndicatorIndex = index;
      this.$refs.carousel.setActiveItem(this.carouselIndicatorIndex);
    },
  },
  computed: {
    titleFirst() {
      return this.title.split("/")[0];
    },
    titleCategory() {
      return "/ " + this.title.split("/")[1];
    },
    itemsPage() {
      return Math.ceil(this.items.length / 3);
    },
  },
  props: {
    background: {
      type: String,
      default: () => "none",
    },
    titleColor: {
      type: String,
      default: () => null,
    },
    title: {
      type: String,
      default: () => "",
    },
    items: {
      type: Array,
      default: () => [],
    },
  },
};
</script>

<style lang="scss" scoped>
.textbook-container {
  .top {
    display: flex;
    flex-direction: column;
    align-items: center;

    .main-title {
      font-size: $font-size-36;
      color: $font-color-black;

      font {
        color: $font-color-orange;
      }
    }
  }
  .body {
    margin-top: 70px;

    // display: flex;
    // justify-content: center;

    // .tab-items {
    padding: 0 228px 0 228px;
    position: relative;

    .carousel {
      width: 100%;
      height: 500px;
      visibility: hidden;
      opacity: 0;

      &.actived {
        visibility: visible;
        opacity: 1;
        transition: 1s;
      }

      .el-carousel {
        height: 100%;
        overflow-y: hidden;

        .items {
          display: flex;
          height: 100%;
          padding-top: 55px;

          .item {
            margin: 0 17px;

            &.not {
              visibility: hidden;
            }
          }
        }
      }
    }

    .action {
      position: absolute;
      left: 130px;
      right: 130px;
      bottom: 0;
      top: -50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;

      .pre,
      .next {
        width: 45px;
        height: 45px;

        background-size: 100%;
        opacity: 0.2;
        transition: 0.25s;

        &:hover {
          transform: scale(1.25);
          opacity: 1;
        }
      }
      &:not(.action_white) {
        .pre {
          background-image: url(/static/img/index/course/icon-pre.png);
        }
        .next {
          background-image: url(/static/img/index/course/icon-next.png);
        }
      }

      &.action_white {
        .pre {
          background-image: url(/static/img/textbook/icon-white-pre.png) !important;
        }
        .next {
          background-image: url(/static/img/textbook/icon-white-next.png) !important;
        }
      }
    }
    // }

    .indicator {
      position: relative;
      z-index: 999;

      display: flex;
      justify-content: center;

      visibility: hidden;
      opacity: 0;

      &.actived {
        visibility: visible;
        opacity: 1;
        transition: 1s;
      }

      .point {
        width: 10px;
        height: 10px;
        background-color: rgba($color: $bg-color-orange, $alpha: 0.1);
        &.action_white {
          background-color: rgba($color: white, $alpha: 0.1) !important;
        }

        margin: 0 5px 0 5px;
        border-radius: 100%;
        transition: 0.5s;
        cursor: pointer;

        &:hover {
          transform: scale(1.25);
        }

        &.actived {
          background-color: rgba($color: $bg-color-orange, $alpha: 1);
          &.action_white {
            background-color: rgba($color: white, $alpha: 1) !important;
          }
        }
      }
    }
  }
}
</style>